import {graphql} from "gatsby"
import React from "react"
import Layout from "../Page"
import Elements from '../../templates/Elements/Elements'
import { isLoggedIn } from "../../services/auth"

const ElementalPage = ({ data: { silverStripeDataObject }}) => {
    const { canViewType } = silverStripeDataObject.SilverStripeSiteTree
    const { elementalContent } = silverStripeDataObject.ElementalPage
    const { metaTags, customCSS} = silverStripeDataObject.Page
    var validPermissions = ['Anyone', 'Inherit']
    if(!validPermissions.includes(canViewType)){
        if(!isLoggedIn()) {
            if (typeof window !== 'undefined') {
                window.location.href = "/login/"
            }
        }
    }
    const pageData = {
        pageClass: 'ElementalPage', 
        bodyClass: '', 
        pageTitle: '',
        metaTags: metaTags,  
        customCSS: customCSS,
    }
    return (
        <Layout pageData={pageData}>
            <Elements data={elementalContent}/>
        </Layout>
    )
}

export default ElementalPage
export const pageQuery = graphql`
    query ($link: String!) {
        silverStripeDataObject(link: { eq: $link }) {
            ElementalPage {
                elementalContent
            }
            SilverStripeSiteTree {
                canViewType
                showInSearch
            }     
            Page{
                metaTags
                customCSS
            }
        }
    }
`